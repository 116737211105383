import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { Canteen } from '@quince/story-canteen';

//const apiURL = process.env.PREACT_APP_API_URL;
const apiURL = 'https://api.fiskarsacademy.com/api/'; // TODO temporary static URL

ReactDOM.render(
    <Canteen
        apiURL={apiURL}
        brandName="fiskars"
        environment="production"
    />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
